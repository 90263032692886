export const paragraphIntroText =
  "Hier kannst du relevante Paragraphen aufklappen und für die Bearbeitung deines Gutachtens nutzen."

export const paragraphContainers = [
  {
    containerTitle: "LMU: Fall 1 (Besitzschutz, insbesondere Anspruch aus § 861 BGB)",
    paragraphs: [
        {
            name: "§ 861 I BGB – Anspruch wegen Besitzentziehung",
            text: " Wird der Besitz durch verbotene Eigenmacht dem Besitzer entzogen, so kann dieser die Wiedereinräumung des Besitzes von demjenigen verlangen, welcher ihm gegenüber fehlerhaft besitzt."
        },
        {
            name: "§ 854 I BGB – Erwerb des Besitzes",
            text: "Der Besitz einer Sache wird durch die Erlangung der tatsächlichen Gewalt über die Sache erworben."
        },
        {
            name: "§ 856 BGB – Beendigung des Besitzes",
            text: "(1) Der Besitz wird dadurch beendigt, dass der Besitzer die tatsächliche Gewalt über die Sache aufgibt oder in anderer Weise verliert. (2) Durch eine ihrer Natur nach vorübergehende Verhinderung in der Ausübung der Gewalt wird der Besitz nicht beendigt."
        },
        {
            name: "§ 858 I BGB – Verbotene Eigenmacht",
            text: "Wer dem Besitzer ohne dessen Willen den Besitz entzieht oder ihn im Besitz stört, handelt, sofern nicht das Gesetz die Entziehung oder die Störung gestattet, widerrechtlich (verbotene Eigenmacht)."
        },
        {
            name: "§ 858 II BGB – Verbotene Eigenmacht",
            text: "Der durch verbotene Eigenmacht erlangte Besitz ist fehlerhaft. Die Fehlerhaftigkeit muss der Nachfolger im Besitz gegen sich gelten lassen, wenn er Erbe des Besitzers ist oder die Fehlerhaftigkeit des Besitzes seines Vorgängers bei dem Erwerb kennt."
        },
        {
            name: "§ 861 II BGB – Anspruch wegen Besitzentziehung",
            text: "Der Anspruch ist ausgeschlossen, wenn der entzogene Besitz dem gegenwärtigen Besitzer oder dessen Rechtsvorgänger gegenüber fehlerhaft war und in dem letzten Jahre vor der Entziehung erlangt worden ist."
        },
        {
            name: "§ 864 I, II BGB – Erlöschen der Besitzansprüche",
            text: "(1) Ein nach den §§ 861, 862 begründeter Anspruch erlischt mit dem Ablauf eines Jahres nach der Verübung der verbotenen Eigenmacht, wenn nicht vorher der Anspruch im Wege der Klage geltend gemacht wird. (2) Das Erlöschen tritt auch dann ein, wenn nach der Verübung der verbotenen Eigenmacht durch rechtskräftiges Urteil festgestellt wird, dass dem Täter ein Recht an der Sache zusteht, vermöge dessen er die Herstellung eines seiner Handlungsweise entsprechenden Besitzstands verlangen kann."
        },
        {
            name: "§ 242 BGB – Leistung nach Treu und Glauben",
            text: "Der Schuldner ist verpflichtet, die Leistung so zu bewirken, wie Treu und Glauben mit Rücksicht auf die Verkehrssitte es erfordern."
        },
        {
            name: "§ 598 BGB – Vertragstypische Pflichten bei der Leihe",
            text: "Durch den Leihvertrag wird der Verleiher einer Sache verpflichtet, dem Entleiher den Gebrauch der Sache unentgeltlich zu gestatten."
        },
        {
            name: "§ 604 I BGB – Rückgabepflicht",
            text: "Der Entleiher ist verpflichtet, die geliehene Sache nach dem Ablauf der für die Leihe bestimmten Zeit zurückzugeben."
        },
        {
            name: "§ 985 BGB – Herausgabeanspruch",
            text: "Der Eigentümer kann von dem Besitzer die Herausgabe der Sache verlangen."
        },
        {
            name: "§ 863 BGB – Einwendungen des Entziehers oder Störers",
            text: "Gegenüber den in den §§ 861, 862 bestimmten Ansprüchen kann ein Recht zum Besitz oder zur Vornahme der störenden Handlung nur zur Begründung der Behauptung geltend gemacht werden, dass die Entziehung oder die Störung des Besitzes nicht verbotene Eigenmacht sei."
        },
    ],
},
  {
    containerTitle: "LMU: Fall 3 (Übereignung gem. §§ 929 ff. BGB)",
    paragraphs: [
        {
            name: "§ 903 BGB – Befugnisse des Eigentümers",
            text: "Der Eigentümer einer Sache kann, soweit nicht das Gesetz oder Rechte Dritter entgegenstehen, mit der Sache nach Belieben verfahren und andere von jeder Einwirkung ausschließen. Der Eigentümer eines Tieres hat bei der Ausübung seiner Befugnisse die besonderen Vorschriften zum Schutz der Tiere zu beachten."
        },
        {
            name: "§ 90a BGB – Tiere",
            text: "Tiere sind keine Sachen. Sie werden durch besondere Gesetze geschützt. Auf sie sind die für Sachen geltenden Vorschriften entsprechend anzuwenden, soweit nicht etwas anderes bestimmt ist."
        },
        {
            name: "§ 929 S. 1 BGB – Einigung und Übergabe",
            text: "Zur Übertragung des Eigentums an einer beweglichen Sache ist erforderlich, dass der Eigentümer die Sache dem Erwerber übergibt und beide darüber einig sind, dass das Eigentum übergehen soll. Ist der Erwerber im Besitz der Sache, so genügt die Einigung über den Übergang des Eigentums."
        },
        {
            name: "§ 930 BGB – Besitzkonstitut",
            text: "Ist der Eigentümer im Besitz der Sache, so kann die Übergabe dadurch ersetzt werden, dass zwischen ihm und dem Erwerber ein Rechtsverhältnis vereinbart wird, vermöge dessen der Erwerber den mittelbaren Besitz erlangt."
        },
        {
            name: "§ 854 I BGB – Erwerb des Besitzes",
            text: "Der Besitz einer Sache wird durch die Erlangung der tatsächlichen Gewalt über die Sache erworben."
        },
        {
            name: "§ 2 BGB – Eintritt der Volljährigkeit",
            text: "Die Volljährigkeit tritt mit der Vollendung des 18. Lebensjahres ein."
        },
        {
            name: "§ 106 BGB –  Beschränkte Geschäftsfähigkeit Minderjähriger",
            text: "Ein Minderjähriger, der das siebente Lebensjahr vollendet hat, ist nach Maßgabe der §§ 107 bis 113 in der Geschäftsfähigkeit beschränkt."
        },
        {
            name: "§ 108 I BGB – Vertragsschluss ohne Einwilligung",
            text: " Schließt der Minderjährige einen Vertrag ohne die erforderliche Einwilligung des gesetzlichen Vertreters, so hängt die Wirksamkeit des Vertrags von der Genehmigung des Vertreters ab."
        },
        {
            name: "§ 1629 BGB – Vertretung des Kindes",
            text: "Die elterliche Sorge umfasst die Vertretung des Kindes. Die Eltern vertreten das Kind gemeinschaftlich; ist eine Willenserklärung gegenüber dem Kind abzugeben, so genügt die Abgabe gegenüber einem Elternteil. Ein Elternteil vertritt das Kind allein, soweit er die elterliche Sorge allein ausübt oder ihm die Entscheidung nach § 1628 übertragen ist. Bei Gefahr im Verzug ist jeder Elternteil dazu berechtigt, alle Rechtshandlungen vorzunehmen, die zum Wohl des Kindes notwendig sind; der andere Elternteil ist unverzüglich zu unterrichten."
        },
        {
            name: "§ 1626 BGB – Elterliche Sorge, Grundsätze",
            text: " (1) Die Eltern haben die Pflicht und das Recht, für das minderjährige Kind zu sorgen (elterliche Sorge). Die elterliche Sorge umfasst die Sorge für die Person des Kindes (Personensorge) und das Vermögen des Kindes (Vermögenssorge). (2) Bei der Pflege und Erziehung berücksichtigen die Eltern die wachsende Fähigkeit und das wachsende Bedürfnis des Kindes zu selbständigem verantwortungsbewusstem Handeln. Sie besprechen mit dem Kind, soweit es nach dessen Entwicklungsstand angezeigt ist, Fragen der elterlichen Sorge und streben Einvernehmen an. (3) Zum Wohl des Kindes gehört in der Regel der Umgang mit beiden Elternteilen. Gleiches gilt für den Umgang mit anderen Personen, zu denen das Kind Bindungen besitzt, wenn ihre Aufrechterhaltung für seine Entwicklung förderlich ist."
        },
        {
            name: "§ 107 BGB – Einwilligung des gesetzlichen Vertreters",
            text: "Der Minderjährige bedarf zu einer Willenserklärung, durch die er nicht lediglich einen rechtlichen Vorteil erlangt, der Einwilligung seines gesetzlichen Vertreters."
        },
        {
            name: "§ 868 I BGB – Mittelbarer Besitz",
            text: "Besitzt jemand eine Sache als Nießbraucher, Pfandgläubiger, Pächter, Mieter, Verwahrer oder in einem ähnlichen Verhältnis, vermöge dessen er einem anderen gegenüber auf Zeit zum Besitz berechtigt oder verpflichtet ist, so ist auch der andere Besitzer (mittelbarer Besitz)."
        },
        {
            name: "§ 688 BGB – Vertragstypische Pflichten bei der Verwahrung",
            text: "Durch den Verwahrungsvertrag wird der Verwahrer verpflichtet, eine ihm von dem Hinterleger übergebene bewegliche Sache aufzubewahren."
        },
        {
            name: "§ 185 I BGB – Verfügung durch Nichtberechtigten mit Zustimmung des Eigentümers",
            text: "Eine Verfügung, die ein Nichtberechtigter über einen Gegenstand trifft, ist wirksam, wenn sie mit Einwilligung des Berechtigten erfolgt."
        },
        {
            name: "§ 932 BGB – Gutgläubiger Erwerb vom Nichtberechtigten",
            text: "(1) Durch eine nach § 929 erfolgte Veräußerung wird der Erwerber auch dann Eigentümer, wenn die Sache nicht dem Veräußerer gehört, es sei denn, dass er zu der Zeit, zu der er nach diesen Vorschriften das Eigentum erwerben würde, nicht in gutem Glauben ist. In dem Falle des § 929 Satz 2 gilt dies jedoch nur dann, wenn der Erwerber den Besitz von dem Veräußerer erlangt hatte. (2) Der Erwerber ist nicht in gutem Glauben, wenn ihm bekannt oder infolge grober Fahrlässigkeit unbekannt ist, dass die Sache nicht dem Veräußerer gehört."
        },
        {
            name: "§ 933 BGB – Erwerb durch gutgläubigen Erwerber bei Übergabe",
            text: "Gehört eine nach § 930 veräußerte Sache nicht dem Veräußerer, so wird der Erwerber Eigentümer, wenn ihm die Sache von dem Veräußerer übergeben wird, es sei denn, dass er zu dieser Zeit nicht in gutem Glauben ist."
        },
        {
            name: "§ 932 II BGB – Gutgläubigkeit des Erwerbers zum Zeitpunkt des Erwerbs",
            text: "Der Erwerber ist nicht in gutem Glauben, wenn ihm bekannt oder infolge grober Fahrlässigkeit unbekannt ist, dass die Sache nicht dem Veräußerer gehört."
        },
    ],
},
  {
    containerTitle: "LMU: Fall 2 (Eigentümer-Besitzer-Verhältnis, insbesondere Anspruch aus §§ 989, 990 BGB)",
    paragraphs: [
        {
            name: "§ 989 BGB – Schadensersatz nach Rechtshängigkeit",
            text: "Der Besitzer ist von dem Eintritt der Rechtshängigkeit an dem Eigentümer für den Schaden verantwortlich, der dadurch entsteht, dass infolge seines Verschuldens die Sache verschlechtert wird, untergeht oder aus einem anderen Grunde von ihm nicht herausgegeben werden kann."
        },
        {
            name: "§ 990 I BGB – Haftung des Besitzers bei Kenntnis",
            text: "War der Besitzer bei dem Erwerb des Besitzes nicht in gutem Glauben, so haftet er dem Eigentümer von der Zeit des Erwerbs an nach den §§ 987, 989. Erfährt der Besitzer später, dass er zum Besitz nicht berechtigt ist, so haftet er in gleicher Weise von der Erlangung der Kenntnis an."
        },
        {
            name: "§ 929 BGB – Einigung und Übergabe",
            text: "Zur Übertragung des Eigentums an einer beweglichen Sache ist erforderlich, dass der Eigentümer die Sache dem Erwerber übergibt und beide darüber einig sind, dass das Eigentum übergehen soll. Ist der Erwerber im Besitz der Sache, so genügt die Einigung über den Übergang des Eigentums."
        },
        {
            name: "§ 932 BGB – Gutgläubiger Erwerb",
            text: "(1) Durch eine nach § 929 erfolgte Veräußerung wird der Erwerber auch dann Eigentümer, wenn die Sache nicht dem Veräußerer gehört, es sei denn, dass er zu der Zeit, zu der er nach diesen Vorschriften das Eigentum erwerben würde, nicht in gutem Glauben ist. In dem Falle des § 929 Satz 2 gilt dies jedoch nur dann, wenn der Erwerber den Besitz von dem Veräußerer erlangt hatte. (2) Der Erwerber ist nicht in gutem Glauben, wenn ihm bekannt oder infolge grober Fahrlässigkeit unbekannt ist, dass die Sache nicht dem Veräußerer gehört."
        },
        {
            name: "§ 935 I BGB – Kein gutgläubiger Erwerb von abhanden gekommenen Sachen",
            text: "Der Erwerb des Eigentums auf Grund der §§ 932 bis 934 tritt nicht ein, wenn die Sache dem Eigentümer gestohlen worden, verloren gegangen oder sonst abhanden gekommen war. Das Gleiche gilt, falls der Eigentümer nur mittelbarer Besitzer war, dann, wenn die Sache dem Besitzer abhanden gekommen war."
        },
        {
            name: "§ 854 I BGB – Erwerb des Besitzes",
            text: "Der Besitz einer Sache wird durch die Erlangung der tatsächlichen Gewalt über die Sache erworben"
        },
        {
            name: "§ 986 BGB – Einwendungen des Besitzers",
            text: "(1) Der Besitzer kann die Herausgabe der Sache verweigern, wenn er oder der mittelbare Besitzer, von dem er sein Recht zum Besitz ableitet, dem Eigentümer gegenüber zum Besitz berechtigt ist. Ist der mittelbare Besitzer dem Eigentümer gegenüber zur Überlassung des Besitzes an den Besitzer nicht befugt, so kann der Eigentümer von dem Besitzer die Herausgabe der Sache an den mittelbaren Besitzer oder, wenn dieser den Besitz nicht wieder übernehmen kann oder will, an sich selbst verlangen. (2) Der Besitzer einer Sache, die nach § 931 durch Abtretung des Anspruchs auf Herausgabe veräußert worden ist, kann dem neuen Eigentümer die Einwendungen entgegensetzen, welche ihm gegen den abgetretenen Anspruch zustehen."
        },
        {
            name: "§ 433 BGB – Vertragstypische Pflichten beim Kaufvertrag",
            text: "(1) Durch den Kaufvertrag wird der Verkäufer einer Sache verpflichtet, dem Käufer die Sache zu übergeben und das Eigentum an der Sache zu verschaffen. Der Verkäufer hat dem Käufer die Sache frei von Sach- und Rechtsmängeln zu verschaffen. (2) Der Käufer ist verpflichtet, dem Verkäufer den vereinbarten Kaufpreis zu zahlen und die gekaufte Sache abzunehmen."
        },
        {
            name: "§ 990 BGB –  Haftung des Besitzers bei Kenntnis",
            text: "(1) War der Besitzer bei dem Erwerb des Besitzes nicht in gutem Glauben, so haftet er dem Eigentümer von der Zeit des Erwerbs an nach den §§ 987, 989. Erfährt der Besitzer später, dass er zum Besitz nicht berechtigt ist, so haftet er in gleicher Weise von der Erlangung der Kenntnis an. (2) Eine weitergehende Haftung des Besitzers wegen Verzugs bleibt unberührt."
        },
        {
            name: "§ 992 BGB – Haftung des deliktischen Besitzers",
            text: "Hat sich der Besitzer durch verbotene Eigenmacht oder durch eine Straftat den Besitz verschafft, so haftet er dem Eigentümer nach den Vorschriften über den Schadensersatz wegen unerlaubter Handlungen."
        },
        {
            name: "§ 823 I BGB – Schadensersatzpflicht",
            text: "(1) Wer vorsätzlich oder fahrlässig das Leben, den Körper, die Gesundheit, die Freiheit, das Eigentum oder ein sonstiges Recht eines anderen widerrechtlich verletzt, ist dem anderen zum Ersatz des daraus entstehenden Schadens verpflichtet. (2) Die gleiche Verpflichtung trifft denjenigen, welcher gegen ein den Schutz eines anderen bezweckendes Gesetz verstößt. Ist nach dem Inhalt des Gesetzes ein Verstoß gegen dieses auch ohne Verschulden möglich, so tritt die Ersatzpflicht nur im Falle des Verschuldens ein."
        },
        {
            name: "§ 993 BGB – Haftung des redlichen Besitzers",
            text: "Liegen die in den §§ 987 bis 992 bezeichneten Voraussetzungen nicht vor, so hat der Besitzer die gezogenen Früchte, soweit sie nach den Regeln einer ordnungsmäßigen Wirtschaft nicht als Ertrag der Sache anzusehen sind, nach den Vorschriften über die Herausgabe einer ungerechtfertigten Bereicherung herauszugeben; im Übrigen ist er weder zur Herausgabe von Nutzungen noch zum Schadensersatz verpflichtet."
        }
    ]
},
{
    containerTitle: "Schadensersatz wegen der verbogenen Fahrradgabel",
    paragraphs: [
        {
            name: "§ 989 BGB –  Schadensersatz nach Rechtshängigkeit",
            text: "Der Besitzer ist von dem Eintritt der Rechtshängigkeit an dem Eigentümer für den Schaden verantwortlich, der dadurch entsteht, dass infolge seines Verschuldens die Sache verschlechtert wird, untergeht oder aus einem anderen Grunde von ihm nicht herausgegeben werden kann."
        },
        {
            name: "§ 990 I BGB –  Haftung des Besitzers bei Kenntnis",
            text: "War der Besitzer bei dem Erwerb des Besitzes nicht in gutem Glauben, so haftet er dem Eigentümer von der Zeit des Erwerbs an nach den §§ 987, 989. 2Erfährt der Besitzer später, dass er zum Besitz nicht berechtigt ist, so haftet er in gleicher Weise von der Erlangung der Kenntnis an."
        },
        {
            name: "§ 276 BGB – Verantwortlichkeit des Schuldners",
            text: "(1) Der Schuldner hat Vorsatz und Fahrlässigkeit zu vertreten, wenn eine strengere oder mildere Haftung weder bestimmt noch aus dem sonstigen Inhalt des Schuldverhältnisses, insbesondere aus der Übernahme einer Garantie oder eines Beschaffungsrisikos, zu entnehmen ist. Die Vorschriften der §§ 827 und 828 finden entsprechende Anwendung. (2) Fahrlässig handelt, wer die im Verkehr erforderliche Sorgfalt außer Acht lässt. (3) Die Haftung wegen Vorsatzes kann dem Schuldner nicht im Voraus erlassen werden."
        },
        {
            name: "§ 823 I BGB – Schadensersatzpflicht",
            text: "Wer vorsätzlich oder fahrlässig das Leben, den Körper, die Gesundheit, die Freiheit, das Eigentum oder ein sonstiges Recht eines anderen widerrechtlich verletzt, ist dem anderen zum Ersatz des daraus entstehenden Schadens verpflichtet."
        },
        {
            name: "§ 993 BGB –  Haftung des redlichen Besitzers",
            text: "(1) Liegen die in den §§ 987 bis 992 bezeichneten Voraussetzungen nicht vor, so hat der Besitzer die gezogenen Früchte, soweit sie nach den Regeln einer ordnungsmäßigen Wirtschaft nicht als Ertrag der Sache anzusehen sind, nach den Vorschriften über die Herausgabe einer ungerechtfertigten Bereicherung herauszugeben; im Übrigen ist er weder zur Herausgabe von Nutzungen noch zum Schadensersatz verpflichtet. (2) Für die Zeit, für welche dem Besitzer die Nutzungen verbleiben, findet auf ihn die Vorschrift des § 101 Anwendung."
        },
        {
            name: "§ 249 BGB – Art und Umfang des Schadensersatzes",
            text: "(1) Wer zum Schadensersatz verpflichtet ist, hat den Zustand herzustellen, der bestehen würde, wenn der zum Ersatz verpflichtende Umstand nicht eingetreten wäre. (2) Ist wegen Verletzung einer Person oder wegen Beschädigung einer Sache Schadensersatz zu leisten, so kann der Gläubiger statt der Herstellung den dazu erforderlichen Geldbetrag verlangen. Bei der Beschädigung einer Sache schließt der nach Satz 1 erforderliche Geldbetrag die Umsatzsteuer nur mit ein, wenn und soweit sie tatsächlich angefallen ist."
        },
    ],
},
  {
    containerTitle: "EidrA: Fall 1",
    paragraphs: [
      {
        name: "§ 631 BGB Vertragstypische Pflichten beim Werkvertrag",
        text: `(1) Durch den Werkvertrag wird der Unternehmer zur Herstellung des versprochenen Werkes, der Besteller zur Entrichtung der vereinbarten Vergütung verpflichtet.
    
  (2) Gegenstand des Werkvertrags kann sowohl die Herstellung oder Veränderung einer Sache als auch ein anderer durch Arbeit oder Dienstleistung herbeizuführender Erfolg sein.`,
      },
      {
        name: "§ 104 BGB Geschäftsunfähigkeit",
        text: `Geschäftsunfähig ist: 
    
(1.) wer nicht das siebente Lebensjahr vollendet hat,

(2.) wer sich in einem die freie Willensbestimmung ausschließenden Zustand krankhafter Störung der Geistestätigkeit befindet, sofern nicht der Zustand seiner Natur nach ein vorübergehender ist.`,
      },
      {
        name: "§ 105 BGB Nichtigkeit der Willenserklärung",
        text: `(1) Die Willenserklärung eines Geschäftsunfähigen ist nichtig.
    
(2) Nichtig ist auch eine Willenserklärung, die im Zustand der Bewusstlosigkeit oder vorübergehender Störung der Geistestätigkeit abgegeben wird.`,
      },
      {
        name: "§ 106 BGB Beschränkte Geschäftsfähigkeit Minderjähriger",
        text: `Bei der Auslegung einer Willenserklärung ist der wirkliche Wille zu erforschen und nicht an dem buchstäblichen Sinne des Ausdrucks zu haften.`,
      },
      {
        name: "§ 107 BGB Einwilligung des gesetzlichen Vertreters",
        text: `Der Minderjährige bedarf zu einer Willenserklärung, durch die er nicht lediglich einen rechtlichen Vorteil erlangt, der Einwilligung seines gesetzlichen Vertreters.`,
      },
      {
        name: "§ 108 BGB Vertragsschluss ohne Einwilligung",
        text: `(1) Schließt der Minderjährige einen Vertrag ohne die erforderliche Einwilligung des gesetzlichen Vertreters, so hängt die Wirksamkeit des Vertrags von der Genehmigung des Vertreters ab.
    
(2) Fordert der andere Teil den Vertreter zur Erklärung über die Genehmigung auf, so kann die Erklärung nur ihm gegenüber erfolgen; eine vor der Aufforderung dem Minderjährigen gegenüber erklärte Genehmigung oder Verweigerung der Genehmigung wird unwirksam. Die Genehmigung kann nur bis zum Ablauf von zwei Wochen nach dem Empfang der Aufforderung erklärt werden; wird sie nicht erklärt, so gilt sie als verweigert.

(3) Ist der Minderjährige unbeschränkt geschäftsfähig geworden, so tritt seine Genehmigung an die Stelle der Genehmigung des Vertreters.`,
      },
      {
        name: "§ 110 BGB Bewirken der Leistung mit eigenen Mitteln",
        text: `Ein von dem Minderjährigen ohne Zustimmung des gesetzlichen Vertreters geschlossener Vertrag gilt als von Anfang an wirksam, wenn der Minderjährige die vertragsmäßige Leistung mit Mitteln bewirkt, die ihm zu diesem Zweck oder zu freier Verfügung von dem Vertreter oder mit dessen Zustimmung von einem Dritten überlassen worden sind.`,
      },
    ],
  },
  {
    containerTitle: "EidrA: Fall 2",
    paragraphs: [
      {
        name: "§ 433 BGB Vertragstypische Pflichten beim Kaufvertrag",
        text: `(1) Durch den Kaufvertrag wird der Verkäufer einer Sache verpflichtet, dem Käufer die Sache zu übergeben und das Eigentum an der Sache zu verschaffen. Der Verkäufer hat dem Käufer die Sache frei von Sach- und Rechtsmängeln zu verschaffen.
    
(2) Der Käufer ist verpflichtet, dem Verkäufer den vereinbarten Kaufpreis zu zahlen und die gekaufte Sache abzunehmen.`,
      },
      {
        name: "§ 145 BGB  Bindung an den Antrag",
        text: `Wer einem anderen die Schließung eines Vertrags anträgt, ist an den Antrag gebunden, es sei denn, dass er die Gebundenheit ausgeschlossen hat.`,
      },
      {
        name: "§ 133 BGB  Auslegung einer Willenserklärung",
        text: `Bei der Auslegung einer Willenserklärung ist der wirkliche Wille zu erforschen und nicht an dem buchstäblichen Sinne des Ausdrucks zu haften.`,
      },
      {
        name: "§ 157 BGB Auslegung von Verträgen",
        text: `Verträge sind so auszulegen, wie Treu und Glauben mit Rücksicht auf die Verkehrssitte es erfordern.`,
      },
    ],
  },
  {
    containerTitle: "EidrA: Fall 3",
    paragraphs: [
      {
        name: "§ 433 BGB Vertragstypische Pflichten beim Kaufvertrag",
        text: `(1) Durch den Kaufvertrag wird der Verkäufer einer Sache verpflichtet, dem Käufer die Sache zu übergeben und das Eigentum an der Sache zu verschaffen. Der Verkäufer hat dem Käufer die Sache frei von Sach- und Rechtsmängeln zu verschaffen.
    
(2) Der Käufer ist verpflichtet, dem Verkäufer den vereinbarten Kaufpreis zu zahlen und die gekaufte Sache abzunehmen.`,
      },
      {
        name: "§ 143 BGB Anfechtungserklärung",
        text: `(1) Die Anfechtung erfolgt durch Erklärung gegenüber dem Anfechtungsgegner.
    
(2) Anfechtungsgegner ist bei einem Vertrag der andere Teil, im Falle des § 123 Abs. 2 Satz 2 derjenige, welcher aus dem Vertrag unmittelbar ein Recht erworben hat.

(3) Bei einem einseitigen Rechtsgeschäft, das einem anderen gegenüber vorzunehmen war, ist der andere der Anfechtungsgegner. Das Gleiche gilt bei einem Rechtsgeschäft, das einem anderen oder einer Behörde gegenüber vorzunehmen war, auch dann, wenn das Rechtsgeschäft der Behörde gegenüber vorgenommen worden ist.

(4) Bei einem einseitigen Rechtsgeschäft anderer Art ist Anfechtungsgegner jeder, der auf Grund des Rechtsgeschäfts unmittelbar einen rechtlichen Vorteil erlangt hat. Die Anfechtung kann jedoch, wenn die Willenserklärung einer Behörde gegenüber abzugeben war, durch Erklärung gegenüber der Behörde erfolgen; die Behörde soll die Anfechtung demjenigen mitteilen, welcher durch das Rechtsgeschäft unmittelbar betroffen worden ist.`,
      },
      {
        name: "§ 133 BGB Auslegung einer Willenserklärung",
        text: `Bei der Auslegung einer Willenserklärung ist der wirkliche Wille zu erforschen und nicht an dem buchstäblichen Sinne des Ausdrucks zu haften.`,
      },
      {
        name: "§ 157 BGB Auslegung von Verträgen",
        text: `Verträge sind so auszulegen, wie Treu und Glauben mit Rücksicht auf die Verkehrssitte es erfordern.`,
      },
      {
        name: "§ 119 BGB Anfechtbarkeit wegen Irrtums",
        text: `(1) Wer bei der Abgabe einer Willenserklärung über deren Inhalt im Irrtum war oder eine Erklärung dieses Inhalts überhaupt nicht abgeben wollte, kann die Erklärung anfechten, wenn anzunehmen ist, dass er sie bei Kenntnis der Sachlage und bei verständiger Würdigung des Falles nicht abgegeben haben würde.
    
(2) Als Irrtum über den Inhalt der Erklärung gilt auch der Irrtum über solche Eigenschaften der Person oder der Sache, die im Verkehr als wesentlich angesehen werden.`,
      },
      {
        name: "§ 121 BGB Anfechtungsfrist",
        text: `(1) Die Anfechtung muss in den Fällen der §§ 119, 120 ohne schuldhaftes Zögern (unverzüglich) erfolgen, nachdem der Anfechtungsberechtigte von dem Anfechtungsgrund Kenntnis erlangt hat. Die einem Abwesenden gegenüber erfolgte Anfechtung gilt als rechtzeitig erfolgt, wenn die Anfechtungserklärung unverzüglich abgesendet worden ist.
    
(2) Die Anfechtung ist ausgeschlossen, wenn seit der Abgabe der Willenserklärung zehn Jahre verstrichen sind.`,
      },
      {
        name: "§ 142 BGB  Wirkung der Anfechtung",
        text: `(1) Wird ein anfechtbares Rechtsgeschäft angefochten, so ist es als von Anfang an nichtig anzusehen.
    
(2) Wer die Anfechtbarkeit kannte oder kennen musste, wird, wenn die Anfechtung erfolgt, so behandelt, wie wenn er die Nichtigkeit des Rechtsgeschäfts gekannt hätte oder hätte kennen müssen.`,
      },
    ],
  },
]
