export const NOTE_ON_APPRAISAL_STYLE_TEXT = `
Der Obersatz bildet die Fragestellung von jedem einzelnen Prüfungspunkt in der Klausur. Am Beispiel der Kausalität (Zusammenhang zwischen Handlung und Erfolg). Der Schuss des A könnte kausal sein für den Tod des B.
Wichtig ist hier der Konjunktiv, denn schließlich weißt du noch nicht, ob der Schuss wirklich kausal war für den Tod des B. Du formulierst also das, was du herausfinden möchtest als Obersatz.
- Es könnte…
- Fraglich ist, ...
- Zu prüfen gilt es, ...
- Zu untersuchen ist, ...
- Zweifelhaft ist, ...
- Sofern; falls; wenn; unter der Bedingung/Voraussetzung, dass;

## Die Definition
Hier definierst du was nötig wäre, damit aus dem Konjunktiv im Obersatz ein Indikativ wird, dabei bringst du die Definition aber ohne Fallbezug, sondern neutral. Hier am Beispiel der Kausalität: Kausal ist eine Handlung für einen Erfolg, gemäß der conditio-sine-qua-non-Formel, dann, wenn sie nicht hinweggedacht werden kann, ohne dass der Erfolg in seiner
konkreten Gestalt entfiele. Mehr als die jeweils passende Definition brauchst du bei diesem Punkt nicht zu erwähnen.

## Die Subsumtion
Passt die Definition zum Sachverhalt? In diesem Gliederungspunkt prüfst du, ob der Sachverhalt der Definition entspricht: Wenn A nicht auf B geschossen hätte, dann wäre B nicht gestorben.

## Das Ergebnis
Hat der Sachverhalt zur Definition gepasst? Hier schreibst du jetzt die Antwort auf die im Obersatz aufgeworfene Frage hin: Demnach war der Schuss des A kausal für den Tod des B.
Voila, Prüfungspunkt im Gutachtenstil abgearbeitet.

Es ist manchmal etwas verschachtelt

Manchmal kannst du einen Obersatz nicht endgültig beantworten, wenn du nicht zuvor einen anderen Obersatz geklärt hast:
A könnte sich gemäß § 212 StGB strafbar gemacht haben, indem er auf B geschossen hat. (Obersatz 1)

Dafür müsste zunächst einmal der objektive Tatbestand des § 212 StGB erfüllt sein. (Obersatz 2)

Es könnte ein Taterfolg vorliegen. (Obersatz 3)

Ein Taterfolg nach § 212 StGB ist der Tod eines Menschen. (Definition des Obersatz 3)

B ist gestorben. (Subsumtion des Obersatz 3)

Demnach liegt ein Taterfolg des § 212 StGB vor. (Ergebnis des Obersatz 3)

Des Weiteren müsste eine Tathandlung vorliegen. (Obersatz 4) …

Zur Erklärung: Der erste Obersatz ist die Fallfrage. Diese Fallfrage kann erst gelöst werden, wenn die einzelnen Obersätze der jeweils untergeordneten Prüfungspunkte beantwortet werden. So müssen für die Falllösung zunächst der objektive und der subjektive Tatbestand, die Rechtswidrigkeit und die Schuld geklärt werden. Um den objektiven Tatbestand zu lösen, müssen die Unterpunkte Erfolg, Handlung usw. geklärt werden. Erst wenn alle Unterpunkte gelöst sind, lässt sich der erste Obersatz, die Fallfrage, beantworten.`

export const NOTE_ON_SUBSUMPTION_TEXT = `Die Subsumtion ist ein wichtiger Teil deiner Falllösung. Um die deine Subsumption noch besser zu formulieren, achte darauf konkrete Tatsachen zu nennen und die Definitionsmerkmale/-voraussetzungen abzuprüfen. Auch Schlussfolgerungen können helfen, deine Subsumtion zu verbessern. Um mehr über die Komponenten zu erfahren, fahre über die Kreise.`

export const NOTE_ON_SUBSUMPTION_TEXT_2 =
  `Die Subsumtion befasst sich, im Gegensatz zur Definition, mit tatsächlichen Problemen. Achte darauf, mit konkreten Tatsachen aus dem Fall die Definitionsvoraussetzungen zu prüfen. Es kann sinnvoll sein, eine Schlussfolgerung (Zwischenergebnis) zu bilden, wenn viele Definitionsvoraussetzungen zu prüfen sind. Wenn du über die Kreise fährst erhälst du ein Beispiel. 
`.trim()

export const NOTE_ON_SUBSUMPTION_EXAMPLE_2 =
  "Er hat jedoch keineswegs dem Vertrag über das Stechen des Tattoos ausdrücklich zugestimmt. Ganz im Gegenteil wusste er von nichts und hatte zuvor eher zum Ausdruck gebracht, dass er von Tätowierungen nichts hält."
export const NOTE_ON_SUBSUMPTION_EXAMPLE =
  "V ist als allein personensorgeberechtigte Person gem. §§ 1626 Abs. 1, 1629 Abs. 1 S. 1, 3 Alt. 1 BGB allein vertretungsberechtigt und gesetzlicher Vertreter der K."
export const NOTE_ON_SUBSUMPTION_CLAIM_EXAMPLE =
  "Es liegt somit auch keine Einwilligung des gesetzlichen Vertreters vor."

export const NO_PLAN_MADE =
  "Du hast keine Skizze angefertigt im vorherigen Schritt."

export const FEEDBACK_BUTTON_CLICK_HELP =
  'Wenn du auf den Button "Feedback" klickst, zeigt dir das System an, welche deiner Sätze dem Gutachtenstil zugeordnet werden können.'

export const FEEDBACK_NOT_YET_RETRIEVED =
  "Hier steht das Feedback wenn du auf den Feedback Button gedrückt hast."

export const TITLE_TEXT_EDITOR =
  "Texteditor – Verfasse hier deine Lösung des Falls und halte dabei den Gutachtenstil ein. Achte darauf, keine unnötigen Leerzeichen einzufügen, da dies die Qualität des Feedbacks beeinträchtigen könnte. Wenn du Überschriften verwendest, beende sie bitte mit einem Punkt (z. B. I. Prüfung des Sachverhalts.). Der Punkt hilft dem System, die Überschrift korrekt als Teil des Gutachtens zu erkennen."

export const CHECKLIST_EXPLANATION = [
  "Die Checkliste wird dir helfen, die juristische Argumentationsstruktur bzw. den‚ Gutachtenstil besser einzuhalten.",
  "Die vier Komponenten des Gutachtenstils sind Obersatz, Definition, Subsumtion und Konklusion (Ergebnis).",
]

export const SUBSUMPTION_SPLIT_EXPLANATION = "Die Subsumtion befasst sich, im Gegensatz zur Definition, mit tatsächlichen Problemen. Achte darauf, mit Tatbestandsmerkmalen die Definitionsvoraussetzungen zu prüfen. Es kann sinnvoll sein, eine Schlussfolgerung (Zwischenergebnis) zu bilden, wenn viele Definitionsvoraussetzungen zu prüfen sind."


export const CHECKLIST_EXPLANATION_TOOLTIP =
  "Die Checkliste zeigt dir die Komponenten, die du in deiner Falllösung aufnehmen musst und gibt dir Empfehlungen zur Textverbesserung. Klicke jeweils auf den Namen einer Komponente, um die Erklärung der Komponente zu sehen. Wenn du auf die kleinen Pfeile gehst, werden die Verbesserungsvorschläge angezeigt. Die vier Komponenten des Gutachtenstils sind Obersatz, Definition, Subsumtion und Konklusion (Ergebnis). Die Subsumtion unterteilt sich nochmals in Prämissen und Schlussfolgerung (mehr Informationen hierzu findest du in der Checkliste)."

export const FEEDBACK_COUNT_COMPONENT_LABEL = "Anzahl"
export const FEEDBACK_COUNT_ERROR_LABEL = "Hinweis"

export const FEEDBACK_EXPLANATION_TOOLTIP_DP_1 = "Dir wird angezeigt, welche Bestandteile der juristischen Argumentationsstruktur bzw. des Gutachtenstils du bereits verwendet hast. Du kannst auch mit dem Mauszeiger über eine Subsumtion fahren und sehen, welche Schlussfolgerungen (Claims) und Prämissen du verwendet hast. Die Checkliste und das Dashboard helfen dir dabei, die juristische Argumentationsstruktur bzw. den Gutachtenstil besser einzuhalten."
export const FEEDBACK_EXPLANATION_TOOLTIP_DP_2 = `
Wenn du auf Feedback klickst, wird dir in der Checkliste angezeigt, ob du Komponenten des Gutachtenstils vergessen hast.  Klicke auf den Pfeil bei Hinweise, um einen Verbesserungshinweis zu erhalten. Überprüfe, wo eventuell eine Komponente fehlt und füge die Fehlende Komponente hinzu, um den Gutachtenstil einzuhalten. Wichtig: In einigen Fällen musst du nicht zwangsläufig etwas verbessern, reflektiere also stets, ob deine geplante Änderung Sinn macht. `.trim()
export const FEEDBACK_EXPLANATION_TOOLTIP_DP_3 = `
Nachdem du auf Feedback geklickt hast, wird dir angezeigt, welche Bestandteile des Gutachtenstils du bereits verwendet hast. Falls Sätze nicht dem Gutachtenstil zuordnerbar sind, überelge wie du Sie verbessern kannst.  Du kannst auch mit dem Mauszeiger über eine Subsumtion fahren und sehen, welche Schlussfolgerungen und konkreten Tatsachen du verwendet hast. Das Dashboard hilft dir dabei, den Gutachtenstil noch besser einzuhalten, lese hier bei "Mehr" die nützlichen Hinweise durch.
`.trim()
export const FEEDBACK_EXPLANATION_TOOLTIP_DP_4 = `
Wenn du auf Feedback klickst, wird dir in der Checkliste angezeigt, ob du Komponenten des Gutachtenstils vergessen hast. Klicke auf den Pfeil bei Hinweise, um einen Verbesserungshinweis zu erhalten. Überprüfe, wo eventuell eine Komponente fehlt und füge Sie hinzu, um den Gutachtenstil einzuhalten. Wichtig: In einigen Fällen musst du nicht zwangsläufig etwas verbessern, reflektiere also stets, ob deine geplante Änderung Sinn macht. `.trim()
export const FEEDBACK_EXPLANATION_TOOLTIP_DP_5 = `
Nachdem du auf Feedback geklickt hast, wird dir angezeigt, welche Bestandteile des Gutachtenstils du bereits verwendet hast. 
Falls sätze nicht dem Gutachtenstil zuordnerbar sind, überelge wie du Sie verbessern kannst. Du kannst auch mit dem Mauszeiger über eine Subsumtion fahren und sehen, welche Schlussfolgerungen und konkreten Tatsachen du verwendet hast. Das Dashboard hilft dir dabei, den Gutachtenstil noch besser einzuhalten, lese hier bei "Mehr" die nützlichen Hinweise durch.`.trim()
export const FEEDBACK_EXPLANATION_TOOLTIP_DP_6 = `
Nachdem du auf Feedback geklickt hast, wird Dir angezeigt, welche Bestandteile des Gutachtenstils du bereits verwendet hast. 
Du kannst auch mit dem Mauszeiger über eine Subsumtion fahren und sehen, welche Schlussfolgerungen und konkreten Tatsachen du verwendet hast. Die Checkliste und das Dashboard helfen dir dabei, den Gutachtenstil noch besser einzuhalten. In der Checkliste wird dir angezeigt, ob du Komponenten des Gutachtenstils vergessen hast.  Klicke auf den Pfeil bei Hinweise, um einen Verbesserungshinweis zu erhalten. Überprüfe, wo eventuell eine Komponente fehlt und füge Sie hinzu, um den Gutachtenstil einzuhalten. Wichtig: In einigen Fällen musst du nicht zwangsläufig etwas verbessern, reflektiere also stets, ob deine geplante Änderung Sinn macht. 
`.trim()
export const FEEDBACK_EXPLANATION_TOOLTIP_DP_7 = FEEDBACK_EXPLANATION_TOOLTIP_DP_6

export const FEEDBACK_EXPLANATION_TOOLTIP_MAP = {
  1: FEEDBACK_EXPLANATION_TOOLTIP_DP_1,
  2: FEEDBACK_EXPLANATION_TOOLTIP_DP_2,
  3: FEEDBACK_EXPLANATION_TOOLTIP_DP_3,
  4: FEEDBACK_EXPLANATION_TOOLTIP_DP_4,
  5: FEEDBACK_EXPLANATION_TOOLTIP_DP_5,
  6: FEEDBACK_EXPLANATION_TOOLTIP_DP_6,
  7: FEEDBACK_EXPLANATION_TOOLTIP_DP_7,
}
