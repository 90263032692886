// const caseStudies = [
//     {
//         "courseName": "Course 1",
//         "cases": [
//             {
//                 "caseName": "Case 1",
//                 "caseStudy": "Case Study 1",
//                 "usefulParagraphs": "Useful Paragraphs 1"
//             }
//         ]
//     }
// ]

export const courseOverviewIntroText = "Wähle hier den Kurs für den du deine Abgabe erstellen möchtest."

const coursesWithCaseStudies = [
  {
    courseName: "EidrA",
    cases: [
      {
        caseName: "Fallübung 1 - Tattoo",
        caseStudy: `K wurde am 3. August 2001 geboren und ist seit einigen Jahren große Verehrerin einer erfolgreichen Rockband.
Dabei gefällt ihr nicht nur die Musik, sondern auch, dass die Mitglieder der Band allesamt zahlreiche
Tätowierungen am Körper tragen. Auch K ist von der Idee fasziniert, ein solches Kunstwerk auf der Haut
zu tragen und möchte sich, als Ausdruck ihrer Persönlichkeit, ebenfalls ein Tattoo stechen lassen. Das Motiv
ist schnell gefunden, es soll das Logo der Rockband sein, das ihr Handgelenk zieren soll.
K lebt mit ihrem Vater V zusammen. Er ist allein sorgeberechtigt und kommt für Ks Unterhalt auf. Zusätzlich
zahlt er ihr ein monatliches Taschengeld von 50 €. Um sich noch etwas dazu zu verdienen, jobbt K in
den Ferien in einem Café, was mit V abgesprochen ist.
K weiß, dass V Tattoos grundsätzlich ablehnt. Als sie früher einmal laut überlegt hatte, welches Tattoo ihr
wohl stehen würde, sagte er genervt, dass diese Tätowierungen ein furchtbarer Trend seien und er überhaupt
nicht verstehe, warum auf einmal alle jungen Leute meinten, sie müssten sich tätowieren lassen.
Spätestens nach zwei Wochen wäre ohnehin ein anderes Motiv „en vogue“ und Entfernungen seien
schmerzhaft und teuer. Davon solle man besser die Finger lassen.
Als Tätowiererin T, deren Stil und Motive K gut gefallen, am Ende der Sommerferien ein neues Tattoo-
Studio in der Stadt eröffnet, erkundigt sich K nach dem Preis. T ist erfreut über die neue Kundin und bietet
ihr das gewünschte Tattoo zu einem Preis von 100 € inklusive aller Nachuntersuchungen an. K, die in der
Saison etwas Geld verdient hat, stimmt begeistert zu. Schon am nächsten Tag, dem 21. August 2018, lässt
sie sich das besprochene Motiv stechen und bezahlt den Preis vollständig in bar. Um eine Diskussion zu
vermeiden, hatte sie V gegenüber nichts von ihrem Vorhaben erwähnt.
Als K einen Tag später zu Hause etwas verlegen V die Verzierung präsentiert und dieser erfährt, wie viel
sie gekostet hat, ist er entsetzt. Er als erziehungsberechtigter Vater hätte doch wohl seine Einwilligung
erteilen müssen. Zudem wüsste K, was er von solcher „Kunst“ halte. Als Erziehungsberechtigter habe er
außerdem die Verantwortung dafür, dass sie den richtigen Umgang mit Geld lerne und keine unbedachten
Entscheidungen treffe. Ohne seine Zustimmung könne sie kein Rechtsgeschäft tätigen. Die 100 € seien
von T zurückzuzahlen. K kann die Aufregung nicht verstehen und findet, dass sie reif genug für eine solche
Entscheidung ist. Außerdem habe sie das Geld selbst verdient und könne es so ausgeben, wie sie es für
richtig halte.

Frage: Ist der zwischen K und T geschlossene Werkvertrag wirksam?

Bearbeitungshinweis: Der Vertragsschluss (Angebot und Annahme) ist nicht zu prüfen. Es
ist außerdem davon auszugehen, dass K den Betrag je zur Hälfte aus dem Taschengeld und
dem Selbstverdienten bezahlt.

`,
        usefulParagraphs: `§§ 631, 104, 105, 106, 107, 108, 110 BGB
                    

                `,
      },
      {
        caseName: "Fallübung 2 - Vertragsschluss per Video?",
        caseStudy: `K und V treffen sich zu Pandemiezeiten über das Videokonferenzsystem Yoom. V erzählt dem K: „Ich will mir einen neuen elektronisch höhenverstellbaren Schreibtisch zulegen. Um Platz zu schaffen, muss ich noch meinen alten Schreibtisch loswerden.“ K ist schon lange genervt davon am Küchentisch zu arbeiten. Ein günstiger Schreibtisch kommt ihm sehr gelegen, weshalb er zu V sagt: „Wie viel willst du für deinen alten Schreibtisch haben? Ich würde ihn nehmen.“ V entgegnet: „Für 80 € ist er dein.“ Bevor K antworten kann bricht die Verbindung ab. K schreibt sodann in den Chat: „Abgemacht, ich nehme ihn!"
Haben K und V einen wirksamen Kaufvertrag geschlossen?
  `,

        usefulParagraphs: `§ 433 BGB, § 145 ff. BGB, §§ 133, 157 BGB 
                   
                `,
      },
      {
        caseName: "Fallübung 3 - Urheberschaft als Eigenschaft?",
        caseStudy: `
                   Kunstsammler K entdeckt im Trödelladen des V ein Ölgemälde, das mit dem Preis von 100 € ausgezeichnet ist. K hält das Gemälde für ein Original des Künstlers Reese, das mindestens einen Wert von 30.000 € haben dürfte. K, das Geschäft seines Lebens witternd, geht überglücklich zu V und schließt mit ihm einen entsprechenden Kaufvertrag. Als er das Gemälde am nächsten Tag abholen möchte, stellt er bei genauer Untersuchung des Gemäldes fest, dass es doch kein Original Reeses ist und somit tatsächlich auch nur 100 € wert ist. Er teilt dem V umgehend mit, dass er nicht am Kaufvertrag festhalten will.
                   
Hat V gegen K einen Anspruch auf Kaufpreiszahlung?

                `,
        usefulParagraphs: `§ 433 BGB, § 143 Abs. 1 BGB, §§ 133, 157 BGB, § 119 II BGB, § 121 BGB, § 142 BGB
                    
                `,
      },
    ],
  },
  {
    courseName: "LMU - Sachenrecht",
    cases: [
      {
        caseName: "Hausaufgabe 1: Fall 1 (Besitzschutz, insbesondere Anspruch aus § 861 BGB) – Bearbeitungszeitraum: 30.10. - 06.11.",
        caseStudy: `
                    B hat eines ihrer Bücher an ihre Kommilitonin A verliehen, wobei eine Dauer der Leihe von zwei Wochen vereinbart wurde. Als B das Buch anschließend wieder von A zurückverlangt, weigert sich A; sie habe es noch nicht fertig gelesen. Während eines Seminars fällt B auf, dass A das Buch in ihrer Tasche hat. Als A in der Kaffeepause auf die Toilette verschwindet, nutzt B die Gelegenheit, nimmt das Buch aus der Tasche der A und steckt es in ihre eigene Tasche. A fällt erst in der Mittagspause auf, dass das Buch fehlt. Als sie B darauf anspricht, gibt diese zwar zu, das Buch an sich genommen zu haben, verweigert aber die Herausgabe unter Berufung auf ihr Eigentum an dem Buch.

Steht A gegen B ein Anspruch auf Herausgabe des Buchs aus § 861 BGB zu?


                `,
        usefulParagraphs: `
§ 861 I BGB
§ 854 I BGB 
§ 856 II BGB
§ 858 I BGB 
§ 858 II BGB
§ 861 II BGB 
§ 864 I, II BGB
§ 242 BGB 
§ 598 BGB 
§ 604 I BGB 
§ 985 BGB
§ 863 BGB 
                `,
      },
      {
        caseName: "Hausaufgabe 2: Fall 3 (Übereignung gem. §§ 929 ff. BGB) – Bearbeitungszeitraum: 06.11. - 27.11.",
        caseStudy: `
                    Während der Sommerferien darf der 12-jährige A auf die Katze seiner Tante B aufpassen. Nach eini-ger Zeit wird ihm die Pflege des Tieres allerdings zu anstrengend. Deshalb bietet A, ohne Rückspra-che mit seinen Eltern oder mit B, beim nächsten Fußballtraining die Katze seinem volljährigen Mannschaftskollegen C an, wobei er überzeugend schildert, die Katze von seinen Eltern zum Ge-burtstag erhalten zu haben. C ist begeistert und nimmt das Angebot an. A und C vereinbaren, dass C sofort Eigentümer werden soll, die Katze aber noch eine Woche, während einer Reise des C, bei A bleiben soll. Anschließend soll C die Katze abholen. Noch bevor dies geschieht, fliegt der Sachver-halt allerdings auf.

Wer ist Eigentümer der Katze?

                `,
        usefulParagraphs: `
§§ 903, 90a BGB 
§ 929 S. 1 BGB 
§ 930 BGB 
§ 854 I BGB 
§ 2, 106 BGB 
§ 108 I BGB 
§§ 1629, 1626 BGB
§ 107 BGB
§ 868 I BGB 
§ 688 BGB 
§ 185 I BGB 
§§ 932, 933 BGB 
§ 932 II BGB 
                `,
      },
      {
        caseName: "Hausaufgabe 3: Fall 2 (Eigentümer-Besitzer-Verhältnis, insbesondere Anspruch aus §§ 989, 990 BGB) – Bearbeitungszeitraum: 27.11.-2.12.",
        caseStudy: `
                   C hat das Fahrrad des A gestohlen. Dieses veräußert C an B, dem er glaubhaft versichert, er habe es selbst bei einem Händler erworben, benötige es aber nicht mehr, da er sich ein besseres Modell angeschafft habe. Bereits bei der ersten Spazierfahrt führt eine Unachtsamkeit des B dazu, dass der Rahmen des Fahrrads zerkratzt wird. Wenige Tage später meldet sich A bei B und klärt den Sachver-halt auf. B erkennt seinen Irrtum und ist bereit, das Fahrrad an A herauszugeben. Zuvor unternimmt er allerdings noch eine letzte Spazierfahrt und ist dabei wiederum unachtsam, was dieses Mal dazu führt, dass die mit dem Rahmen fest verbundene Fahrradgabel verbogen wird. A freut sich, dass er das Fahrrad wieder erhält, verlangt aber von B Schadensersatz wegen des Rahmens und der Fahr-radgabel.

Steht A gegen B ein Anspruch auf Schadensersatz zu?


                `,
        usefulParagraphs: `
Schadensersatz wegen des zerkratzten Rahmens:

§§ 989, 990 I BGB 
§ 929 S. 1 BGB 
§ 932 BGB 
§ 935 I BGB
§ 854 I BGB 
§ 986 BGB 
§ 433 BGB 
§ 990 I 1, 2 BGB 
§ 992 BGB 
§ 823 I BGB 
§ 993 I Hs. 2 BGB 

Schadensersatz wegen der verbogenen Fahrradgabel:

§§ 989, 990 I BGB 
§ 990 I 2 BGB 
§ 989 BGB 
§ 276 BGB 
§ 823 I BGB 
§ 993 I Hs. 2 BGB 
§§ 249 ff. BGB
                `,
      },
    ],
  },
]

export default function getCoursesWithCaseStudies() {
  return coursesWithCaseStudies
}
